import React, { useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { useCameras } from './useCameras';

import styles from './app.module.css';

async function startCapture(displayMediaOptions: MediaStreamConstraints) {
    let captureStream: MediaStream | undefined = undefined;

    try {
        //@ts-ignore
        captureStream = (await navigator.mediaDevices.getDisplayMedia(
            displayMediaOptions
        )) as MediaStream;
    } catch (err) {
        console.error('Error: ' + err);
    }
    return captureStream;
}

interface StreamProps {
    stream: MediaStream | undefined;
    onClick: (videoEl: HTMLVideoElement) => void;
}

const Screen: React.FC<StreamProps> = ({ stream, onClick }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useLayoutEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    if (stream) {
        return (
            <div
                className={styles.item}
                onClick={() => videoRef.current && onClick(videoRef.current)}
            >
                <video
                    ref={videoRef}
                    className={styles.thumb}
                    autoPlay
                    width={1024}
                    height={768}
                ></video>
            </div>
        );
    }

    return (
        <div className={styles.item}>
            <div className={styles.thumb}>Connecting...</div>
        </div>
    );
};

function App() {
    const [currentVideo, setCurrentVideo] = useState<HTMLVideoElement | undefined>();
    const [windows, setWindows] = useState<MediaStream[]>([]);
    const [shareWindow, setShareWindow] = useState<Window | undefined>();
    const [canvas, setCanvas] = useState<OffscreenCanvas | undefined>();
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

    const [areCamerasActive, setCamerasActive] = useState(false);
    const [cameraDevices, cameraStreams, hasCameraPermission, requestCameraPermission] =
        useCameras(areCamerasActive);

    const selectStream = useCallback(
        async (videoEl: HTMLVideoElement) => {
            window.postMessage(
                {
                    type: 'show-canvas',
                },
                '*'
            );
            setCurrentVideo(videoEl);
        },
        [setCurrentVideo]
    );

    const openShareWindow = useCallback(() => {
        const newWindow = window.open(
            '?share-window',
            Math.random().toString(),
            'width=1920,height=1080,menubar=0,status=0,toolbar=0,location=0'
        );
        if (newWindow) {
            setShareWindow(newWindow);
            newWindow.addEventListener('message', (message: MessageEvent) => {
                newWindow.addEventListener('unload', () => {
                    setCurrentVideo(undefined);
                    setShareWindow(undefined);
                    setCanvas(undefined);
                });
                if (message.data.type === 'set-canvas') {
                    setCanvas(message.data.canvas);
                }
            });
        } else {
            alert('error opening window');
        }
    }, [setCurrentVideo, setShareWindow, setCanvas]);

    const addWindow = async () => {
        const stream = await startCapture({});
        if (stream) {
            setWindows([...windows, stream]);
            stream.onremovetrack = () => setWindows(windows.filter((curr) => stream !== curr));
            window.focus();
        }
    };

    useEffect(() => {
        let stillGoing = true;
        if (currentVideo && previewCanvasRef.current) {
            const previewCanvas = previewCanvasRef.current;
            requestAnimationFrame(function keepGoing() {
                if (!stillGoing) {
                    return;
                }
                let width = currentVideo.videoWidth;
                let height = currentVideo.videoHeight;
                if (width !== previewCanvas.width) {
                    const ratio = previewCanvas.width / width;
                    width = width * ratio;
                    height = height * ratio;
                }

                if (height > previewCanvas.height) {
                    const ratio = previewCanvas.height / height;
                    width = width * ratio;
                    height = height * ratio;
                }

                const xOffset = Math.floor((previewCanvas.width - width) / 2);
                const yOffset = Math.floor((previewCanvas.height - height) / 2);

                if (canvas) {
                    canvas
                        .getContext('2d')
                        ?.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
                    canvas
                        .getContext('2d')
                        ?.drawImage(currentVideo, xOffset, yOffset, width, height);
                }
                if (previewCanvas) {
                    previewCanvas
                        .getContext('2d')
                        ?.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
                    previewCanvas
                        .getContext('2d')
                        ?.drawImage(currentVideo, xOffset, yOffset, width, height);
                }
                requestAnimationFrame(keepGoing);
            });
        }
        return () => {
            stillGoing = false;
        };
    }, [currentVideo, canvas]);

    return (
        <div className="App">
            <canvas
                width={1920 * window.devicePixelRatio}
                height={1080 * window.devicePixelRatio}
                className={styles.preview}
                ref={previewCanvasRef}
            />
            {shareWindow ? (
                'Share window is open'
            ) : (
                <button onClick={openShareWindow}>Open Sharing Window</button>
            )}
            <hr />

            <h2>Shares</h2>
            <div className={styles.items}>
                {windows.map((stream) => (
                    <Screen key={stream.id} stream={stream} onClick={selectStream} />
                ))}
                <button onClick={addWindow} className={styles.addButton}>
                    + Add
                </button>
            </div>
            <h2>
                <label>
                    <input
                        type="checkbox"
                        checked={areCamerasActive}
                        onChange={(event) => setCamerasActive(!!event.currentTarget.checked)}
                    />{' '}
                    Show Cameras
                </label>
            </h2>
            {areCamerasActive && (
                <div className={styles.items}>
                    {hasCameraPermission
                        ? cameraStreams.map((stream) => (
                              <Screen key={stream.id} stream={stream} onClick={selectStream} />
                          ))
                        : cameraDevices.map((device) => (
                              <div className={styles.item} key={device.deviceId}>
                                  <div className={styles.thumb}>
                                      <button onClick={requestCameraPermission}>Add</button>
                                  </div>
                              </div>
                          ))}
                </div>
            )}
            {/* <h2>Websites</h2>
                <input type="text" onKeyDown={addUrl} />
                <div className={styles.items}>
                    {urls.map((url, i) => (
                        <div key={i} onClick={() => selectUrl(url)}>
                            {url}
                        </div>
                    ))}
                </div> */}
        </div>
    );
}

export default App;
