import { useState, useCallback, useEffect, useRef } from 'react';

interface CamerasState {
    hasPermission: boolean;
    hasDevices: boolean;
    devices: MediaDeviceInfo[];
    streams: MediaStream[];
}

export const useCameras = (isActive: boolean) => {
    const gettingDevices = useRef<boolean>(false);
    const [state, setState] = useState<CamerasState>({
        hasPermission: false,
        hasDevices: false,
        devices: [],
        streams: [],
    });

    const getDevices = useCallback(async () => {
        if (gettingDevices.current) {
            return;
        }
        const deviceList = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = deviceList.filter((device) => device.kind === 'videoinput');
        const updatedState: Partial<CamerasState> = {};
        if (videoDevices.length === 0) {
            updatedState.hasDevices = false;
        } else {
            if (state.hasDevices === false) {
                updatedState.hasDevices = true;
            }
            updatedState.devices = videoDevices;
            if (!!videoDevices[0].label) {
                updatedState.hasPermission = !!videoDevices[0].label;
                if (updatedState.hasPermission) {
                    const streams = await Promise.all(
                        videoDevices.map((device) => {
                            return navigator.mediaDevices.getUserMedia({
                                video: { deviceId: { exact: device.deviceId } },
                            });
                        })
                    );
                    updatedState.streams = streams;
                }
            }
        }
        setState({
            ...state,
            ...updatedState,
        });
    }, [state]);

    const requestPermission = useCallback(async () => {
        if (state.hasPermission || state.devices.length === 0) {
            return;
        }

        await navigator.mediaDevices.getUserMedia({ video: true });
        await getDevices();
    }, [getDevices, state.devices.length, state.hasPermission]);

    useEffect(() => {
        if (isActive) {
            getDevices();
            gettingDevices.current = true;
        } else {
            gettingDevices.current = false;
            if (state.streams.length) {
                for (const stream of state.streams) {
                    for (const track of stream.getTracks()) {
                        track.stop();
                    }
                    for (const track of stream.getAudioTracks()) {
                        track.stop();
                    }
                    for (const track of stream.getVideoTracks()) {
                        track.stop();
                    }
                }
                setState((currentState) => ({
                    ...currentState,
                    devices: [],
                    streams: [],
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDevices, isActive]);

    return [state.devices, state.streams, state.hasPermission, requestPermission] as const;
};
