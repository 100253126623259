import React, { useRef, useEffect, useState, useMemo } from 'react';

export const ShareWindow = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const urls = useMemo<string[]>(() => [], []);
    const [currentUrl, setCurrentUrl] = useState<string | undefined>();

    useEffect(() => {
        document.title = 'Sunroom Share Window';
    }, []);

    useEffect(() => {
        if (canvasRef.current && window.opener) {
            try {
                const transferrable = canvasRef.current.transferControlToOffscreen();
                window.postMessage(
                    {
                        type: 'set-canvas',
                        canvas: transferrable,
                    },
                    '*',
                    // @ts-ignore
                    [transferrable]
                );
            } catch (e) {}
        }
    }, [canvasRef.current === null]);

    useEffect(() => {
        function setUrlOrCanvas(event: MessageEvent) {
            if (event.data.type === 'show-iframe') {
                if (urls.includes(event.data.url) === false) {
                    urls.push(event.data.url);
                }
                setCurrentUrl(event.data.url);
            }
            if (event.data.type === 'show-canvas') {
                setCurrentUrl(undefined);
            }
        }
        window.opener.addEventListener('message', setUrlOrCanvas);
        return () => {
            window.opener.removeEventListener('message', setUrlOrCanvas);
        };
    }, [urls, setCurrentUrl]);

    return (
        <>
            <canvas
                style={{
                    width: '100vw',
                    position: 'absolute',
                    visibility: currentUrl === undefined ? 'visible' : 'hidden',
                }}
                width={1920 * window.devicePixelRatio}
                height={1080 * devicePixelRatio}
                ref={canvasRef}
                key="abc"
            />
            {urls.map((url) => (
                <iframe
                    title="website"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        visibility: currentUrl === url ? 'visible' : 'hidden',
                    }}
                    key={url}
                    src={url}
                />
            ))}
        </>
    );
};
